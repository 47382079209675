import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { Box, FileInput, Heading, Image, ResponsiveContext } from "grommet";
import BackofficeService from '../../../services/backofficeService';

const EventImages: FunctionComponent = () => {

  const size = useContext(ResponsiveContext);

  const [header, setHeaderImage] = useState<string | null>();
  const [footer, setFooterImage] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);


  const updateImage = async (file: File, type: 'header' | 'footer') => {
    await BackofficeService.setImage(file, type);
    if(type === 'header') {
      await retrieveHeaderImage();
    } else {
      await retrieveFooterImage();
    }
  }

  const retrieveHeaderImage = async() => {
    const headerImg = await BackofficeService.getHeaderImage();
    if(headerImg) {
      setHeaderImage(headerImg);
    }
  }
  const retrieveFooterImage = async() => {
    const footerImg = await BackofficeService.getFooterImage();
    if(footerImg) {
      setFooterImage(footerImg);
    }  
  }

  useEffect(() => {
    let isMounted = true;
    const fetchImages = async () => {
      setLoading(true);
      await retrieveFooterImage();
      await retrieveHeaderImage();
    }
    if(isMounted) {
      fetchImages().then(() => setLoading(false))
    }
    return () => { isMounted = false };
  }, []);

  if(loading) {
    return <Heading level="3"> Chargement des images...</Heading>
  }

  return <Box flex direction="column" justify="center" align="center">

    <Box flex direction={size === "large" ? "row" : "column"} width="xlarge" justify="around" align="center" margin="medium">
      <Box flex direction="column" 
        width={size === "large" ? "small" : "fill"}
        align={size === "large" ? "baseline" : "center"} 
        justify="center"  pad="small"  >
        <Heading level="4">
            Image en haut de page
        </Heading>

        <FileInput 
          width="medium" name="imgHeader" id="headerImg" color="pexeGreen" 
          onChange={event => {
            event.preventDefault();
            const images = event.target.files;
            if(images && images[0]) {
              const { type } = images[0];
              if(type === 'image/png' || type === 'image/jpeg') {
                updateImage(images[0], 'header')
              }
            }
          }}
          messages={{browse: "Ouvrir l'explorateur", dropPrompt: "Déposer un fichier"}} 
        />
      </Box>
      <Box height="medium" width="large" overflow="hidden" border="all">   
        <Image fit="contain" src={ header || `${process.env.PUBLIC_URL}/noimage.jpg`} />
      </Box>     
    </Box>

    <Box flex direction={size === "large" ? "row" : "column"} width="xlarge" justify="around" align="center" margin="medium">
      <Box flex direction="column" 
        width={size === "large" ? "small" : "fill"}
        align={size === "large" ? "baseline" : "center"} 
        justify="center"  pad="small"  >
        <Heading level="4">
            Image en bas de page
        </Heading>

        <FileInput width="medium" name="imgFooter" id="footerImg" 
          color="pexeGreen" 
          onChange={event => {
            event.preventDefault();
            const images = event.target.files;
            if(images && images[0]) {
              const { type } = images[0];
              if(type === 'image/png' || type === 'image/jpeg') {
                updateImage(images[0], 'footer')
              }
            }
          }}
          messages={{browse: "Ouvrir l'explorateur", dropPrompt: "Déposer un fichier"}} 
        />
      </Box>
      <Box height="medium" width="large" overflow="hidden" border="all">   
        <Image fit="contain" src={ footer || `${process.env.PUBLIC_URL}/noimage.jpg`} />
      </Box>      
    </Box>


  </Box>
};



export default EventImages;