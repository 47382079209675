/* eslint-disable @typescript-eslint/no-unused-vars */
import { useHistory } from 'react-router-dom';
import { Box, Form, TextInput, Button, Heading } from 'grommet';
import { Mail, Login as LoginIcon, Lock, User } from 'grommet-icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isLoggedIn, login } from '../../services/authService';
import PexeSpinner from '../shared/PexeSpinner';

interface Login {
  password: string;
  username: string;
}

const Login: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSubmit, register, errors, formState } = useForm({ mode: 'onChange' });
  const [isLoading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);


  const history = useHistory();
  const { isDirty, isValid } = formState;


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleValidLogin = async (formValues: Login) => {
    const { username, password } = formValues;

    setLoading(true);
    const loggedIn = await login(username, password);
    setLoading(false);
    if(loggedIn) history.push("/backoffice");

    if(!loggedIn) setErrorLogin(true);
  };

  useEffect(() => {
    if(isLoggedIn()) {
      history.push("/backoffice");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      flex
      as="nav"
      direction="row"
      align="center"
      height="medium"
      justify="center"

      responsive
      pad="medium"
    >
      <Box width="medium" elevation="medium" style={{display: "flex", flexDirection:"column", alignItems: "center", paddingBottom: "10px"}}>

        <img src={`${process.env.PUBLIC_URL}/pexe-logo.jpg`} alt='pexe' style={{width: '50%', height: "auto", margin: "15px"}}/>

        { isLoading && <PexeSpinner />}

        { !isLoading && errorLogin &&
          <Heading style={{margin: '10px'}} level="6" color="status-critical">
              Impossible de se connecter.
          </Heading>
        }

        { !isLoading &&
        <Form onSubmit={handleSubmit(handleValidLogin)}>
          <Heading style={{margin: '10px'}} level="6" color="status-critical">
            {errors.username && errors.username.message}
          </Heading>
          <TextInput
            id="username-id"
            name="username"
            type="input"
            autoComplete="true"
            placeholder="Nom d'utilisateur"
            icon={<User style={{ paddingLeft: '6px' }} color="darkBlue" />}
            style={{ margin: '10px' }}
            ref={register({
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]*$/i,
                message: "Nom d'utilisateur invalide",
              },
            })}
          />

          <Heading style={{margin: '10px'}} level="6" color="status-critical">
            {errors.password && errors.password.message}
          </Heading>
          <TextInput
            id="password-id"
            name="password"
            type="password"
            placeholder="Mot de passe"
            icon={<Lock style={{ paddingLeft: '6px' }} color="darkBlue" />}
            style={{ margin: '10px' }}
            ref={register({
              required: true,
              pattern: {
                value: /^[\s\S]*$/i,
                message: 'Mot de passe invalide',
              },
            })}
          />
          <Box direction="row" flex justify="center" pad="medium">
            <Button
              disabled={!isDirty || !isValid}
              icon={<LoginIcon />}
              color="lightBlue"
              type="submit"
              primary
              label="Se connecter"
            />
          </Box>
        </Form>
        }

      </Box>
    </Box>
  );
};

export default Login;
