import { Box, Button, DropButton, Heading, Paragraph, ResponsiveContext } from "grommet";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import dateFormat from 'dateformat';
import { Close, Configure, Duplicate, Edit, Trash } from "grommet-icons";
import PexeEvent from "../../../entities/event.interface";
import backofficeService from "../../../services/backofficeService";
import datei18nFR from "../../../utils/dateFormat.i18n.fr";
import PexeSpinner from "../../shared/PexeSpinner";
import EditEvent from "./EditEvent";
import ConfirmModal from "../../shared/ConfirmModal";

dateFormat.i18n = datei18nFR;

const PastEvents: FunctionComponent = () => {

  const size = useContext(ResponsiveContext);

  const [pastEvents, setPastEvents] = useState<PexeEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [currentEdit, setCurrentEdit] = useState<number>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionDelete, setDeleteAction] = useState<(confirmed: boolean) => Promise<void>>(() => Promise.resolve());


  const setProxyDelete = (fn: any, param: PexeEvent) => {
    setShowConfirmModal(true);
    setDeleteAction(() => fn(param));
  }

  const closeConfirm = () => {
    setShowConfirmModal(false);
    setDeleteAction(() => Promise.resolve());
  }

  const deleteEvent = (event: PexeEvent) => async (confirmed: boolean) => {
    closeConfirm();
    if (confirmed && event) {
      setLoading(true);
      await backofficeService.deleteEvent(event.id);
      setPastEvents(pastEvents.filter(ev => ev.id !== event.id));
      setLoading(false);
    }
  }

  const duplicateEvent = async (event: PexeEvent) => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...eventWithoutId } = event;
    const duplicatedEvent = { ...eventWithoutId, finished: true, current: false } as PexeEvent;
    await backofficeService.createEvent(duplicatedEvent);
    setPastEvents([...pastEvents, duplicatedEvent]);
    setLoading(false);
  }

  const openEditEvent = (event: PexeEvent) => {
    setEdit(true);
    setCurrentEdit(event.id);
  }

  const closeEditEvent = () => {
    setEdit(false);
    setCurrentEdit(0);
  }

  useEffect(() => {
    let isMounted = true;
    (async function getPastThematics() {
      setLoading(true);
      const retrievedPastEvents = await backofficeService.retrievePastEvents();
      if (isMounted) {
        setPastEvents(retrievedPastEvents);
      }
      setLoading(false);
    })();
    return () => { isMounted = false };
  }, []);

  if (loading) return <PexeSpinner />

  return <Box flex direction="column" background="#f3f3f3">
    <Box width={size === "large" ? "70%" : "95%"} margin="0 auto">
      {showConfirmModal && <ConfirmModal confirmAction={actionDelete} closeAction={closeConfirm} />}
      <Box flex direction="column" fill pad="large">
        {
          pastEvents && pastEvents.length > 0 ?
            pastEvents.map(event => {
              return <Box key={event.id} elevation="medium" pad="medium" flex direction="column" align="start" fill width="xlarge" background="white" margin="medium">
                <Box flex fill direction="row" justify="between" align="center">
                  <Box flex direction="column">
                    <Heading level="3"> {event.eventName} - {dateFormat(event.date, "dd/mm/yyyy")} </Heading>
                    <Paragraph style={{ overflowWrap: "break-word" }} >
                      {event.presentationTextEn}
                    </Paragraph>
                  </Box>

                  <DropButton
                    icon={<Configure />}
                    color="pexeGreen"
                    margin={{ bottom: "10px" }}
                    label="Actions"
                    dropAlign={{ top: 'bottom', right: size === 'small' ? 'right' : 'left' }}
                    dropContent={
                      <>
                        {
                          edit && currentEdit === event.id ?
                            <Button
                              margin={{ vertical: "small", horizontal: "small" }}
                              type="button"
                              onClick={() => closeEditEvent()}
                              reverse
                              icon={<Close />}
                              color="status-dark"
                              label="Fermer l'édition" />
                            :
                            <Button
                              margin={{ vertical: "small", horizontal: "small" }}
                              type="button"
                              onClick={() => openEditEvent(event)}
                              reverse
                              icon={<Edit />}
                              color="status-dark"
                              label="Editer l'événement" />
                        }
                        <Button
                          margin={{ vertical: "small", horizontal: "small" }}
                          type="button"
                          onClick={() => duplicateEvent(event)}
                          reverse
                          icon={<Duplicate />}
                          color="status-dark"
                          label="Dupliquer l'événement" />
                        <Button
                          margin={{ vertical: "small", horizontal: "small" }}
                          type="button"
                          onClick={() => setProxyDelete(deleteEvent, event)}
                          reverse
                          icon={<Trash />}
                          color="status-error"
                          label="Supprimer l'événement" />
                      </>
                    }
                  />
                </Box>
                {
                  edit && currentEdit === event.id &&
                  <EditEvent event={event} />
                }
              </Box>
            })
            : <Heading level="3"> Aucun événement passé à afficher.</Heading>
        }
      </Box>
    </Box>
  </Box>
}
export default PastEvents;