/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent } from 'react';

import {Box, Button, CheckBox, Text} from "grommet";
import { Download } from 'grommet-icons';
import PexeEvent from '../../../entities/event.interface';
import BackofficeService from '../../../services/backofficeService';


type EventParametersProps = {
  event: PexeEvent,
  setEvent: any,
  register: any,
  resultsFileDownload: any,
  createMode?: boolean
}
const EventParameters: FunctionComponent<EventParametersProps>
= ({event, resultsFileDownload, register, setEvent, createMode = false}) => {

  const openEnglishInformation = (enAvailable: boolean) => {
    event.en_available = enAvailable;
    setEvent(event, false);
  }

  const exportResults = () => {
    resultsFileDownload();
  };

  return <Box flex direction="row" justify="between" style={{margin: "0 auto"}} align="center" width="xlarge">
    <Box flex direction="column" align="stretch">
      <Box pad="medium">
        <CheckBox
          name="en_available"
          style={{padding: "15px"}}
          label="Ajouter une version anglaise"
          onChange={(e) => openEnglishInformation(e.target.checked)}
          ref={register()}
          defaultChecked={event.en_available}
        />
      </Box>
      <Box pad="medium">
        <CheckBox
          name="glass"
          label="Ajouter des vitrines"
          ref={register()}
          defaultChecked={event.glass}
        />
      </Box>
    </Box>

    <Box flex direction="column" justify="between" style={{margin: "0 auto"}} align="center" width="xlarge">
      { !createMode &&
      <Box fill flex align="end" justify="center" >
        <Button icon={<Download />} label="Exporter les résultats"  type="button" color="pexeGreen" onClick={() => exportResults()} />
      </Box>
      }
      {!createMode && !event.glass &&
          <Box fill flex align="end" justify="center">
              <Text style={{margin: "7px"}}>Nombre de téléchargements de la fiche
                  contact: {event.countContactDownload ?? 0}</Text>
          </Box>
      }
    </Box>

  </Box>
};

export default EventParameters;
