/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Box, ResponsiveContext, TextInput, Button, Heading } from "grommet";
import { Checkbox, CheckboxSelected, Checkmark, CoatCheck, Save, Trash } from "grommet-icons";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Criterion } from "../../../entities/criterion.interface";

type CriterionComponentTypes = {
  criterion: Criterion,
  deleteCriterion: any,
  updateCriterion: any,
  actionStarted: boolean,
  englishAvailable: boolean
}
const CriterionComponent: FunctionComponent<CriterionComponentTypes>
  = ({ criterion, deleteCriterion, englishAvailable, actionStarted, updateCriterion }) => {

    const size = useContext(ResponsiveContext);
    const { register, getValues, formState } = useForm({ mode: 'all' });

    const modifyCriterion = () => {
      const updatedCriterion: any = { ...criterion, ...getValues() };
      updatedCriterion.weight = parseInt(updatedCriterion.weight, 10);
      updateCriterion(updatedCriterion);
    }

    return <Form style={{ marginBottom: "30px" }}>

      <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="around">
        <Box width="medium">
          <TextInput
            style={{ margin: "7px" }}
            type="text"
            defaultValue={criterion.criteriaFr}
            name="criteriaFr" ref={register({ required: true })}
            placeholder="[FRANCAIS] Nom du critère" />
          {
            englishAvailable && <TextInput
              style={{ margin: "7px" }}
              name="criteriaEn"
              type="text"
              defaultValue={criterion.criteriaEn && criterion.criteriaEn !== " " ? criterion.criteriaEn : ""}
              ref={register({ required: true })}
              placeholder="[ANGLAIS] Nom du critère" />
          }
          <TextInput
            style={{ margin: "7px" }}
            type="number"
            reverse
            min="0"
            max="100"
            icon={<Heading level="3">%</Heading>}
            defaultValue={criterion.weight || 0}
            name="weight"
            ref={
              register(
                {
                  required: true,
                  min: 0,
                  max: 100,
                  pattern: {
                    value: /^[1-9][0-9]?$|^100$/,
                    message: ""
                  }
                }
              )
            }
            placeholder="Poids du critère (%)" />
        </Box>
      </Box>

      <Box fill pad="small" flex direction="row" justify="center" align="center">
        <Button
          icon={<Checkmark />}
          margin={{ horizontal: "small" }}
          disabled={!formState.isValid || actionStarted}
          onClick={() => modifyCriterion()}
          label="Enregistrer"
          color="pexeGreen" />

        <Button
          label="Supprimer"
          disabled={actionStarted}
          style={{ opacity: !formState.isValid || actionStarted ? "0.3" : "1" }}
          color="status-critical"
          icon={<Trash />}
          onClick={() => deleteCriterion(criterion.id)}
        />
      </Box>
    </Form>
  }
export default CriterionComponent;