/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Heading, ResponsiveContext } from "grommet";
import React, { FunctionComponent, useContext, useState } from "react";
import PexeEvent from "../../../entities/event.interface";
import BackofficeService from "../../../services/backofficeService";
import PexeSpinner from "../../shared/PexeSpinner";
import EventComponent from "./Event";

const EditEvent: FunctionComponent<{ event: PexeEvent }> = ({ event }) => {

	const size = useContext(ResponsiveContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [showSpinner, setSpinner] = useState<boolean>(false);
	const [showError, setShowError] = useState<boolean>(false);

	const setEvent = async (updatedEvent: PexeEvent) => {
		const savedEvent = await BackofficeService.saveEvent(updatedEvent);
		if (savedEvent) {
			event = savedEvent;
		}
	};


	if (showSpinner) return <PexeSpinner />

	return <Box flex direction="column" background="#f3f3f3">
		<Box width={size === "large" ? "70%" : "95%"} margin="0 auto">
			<Box flex direction="column" fill pad="large">
				{
					showError && <Heading level="4" color="status-error"> Une erreur est survenue. Veuillez réessayer </Heading>

				}
				{
					event && <EventComponent editMode event={event} setEvent={setEvent} loading={loading} setLoading={setLoading} />
				}
			</Box>
		</Box>
	</Box>

}

export default EditEvent;