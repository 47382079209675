/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Heading, ResponsiveContext } from 'grommet';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Criteria from './criteria/Criteria';
import Companies from './companies/Companies';
import Event from './event/Event';
import BackofficeService from '../../services/backofficeService';
import PexeEvent from '../../entities/event.interface';
import ThematicsGlass from './thematics/ThematicsGlass';
import PexeSpinner from '../shared/PexeSpinner';

const HomeContent: FunctionComponent = () => {

  const size = useContext(ResponsiveContext);
  const [isLoadingEvent, setLoadingEvent] = useState(false);
  const [event, setEvent] = useState<PexeEvent | null>();

  useEffect(() => {
    let isMounted = true;
    if(isMounted) {
      setLoadingEvent(true);
      BackofficeService.retrieveCurrentEvent()
        .then((currentEvent) => {
          if(currentEvent) {
            setEvent(currentEvent)
          }
        })
        .finally(() => setLoadingEvent(false));
    }

    return () => { isMounted = false };
  }, []);

  if(isLoadingEvent) {
    return <PexeSpinner />
  }

  if(!event) {
    return <Heading style={{padding: "35px"}} level="3"> Aucun événement courant. </Heading>
  }

  return (
    <Box
      flex
      as="nav"
      direction="column"
      responsive
      style={{
        width: size === "large" ? "70%" : "100%",
        margin: "0 auto"
      }}
      pad={size === "small" ? "xsmall" : "medium"}
    >

      <Event event={event} setEvent={setEvent} loading={isLoadingEvent} setLoading={setLoadingEvent}/>
      { event.glass ?
        <ThematicsGlass englishAvailable={event.en_available} />
        : <>
          <Companies englishAvailable={event.en_available} glass={event.glass} />
          <Criteria englishAvailable={event.en_available} glass={event.glass} />
        </>
      }
    </Box>
  );
};

export default HomeContent;
