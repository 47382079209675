import { Box } from 'grommet';
import React, { FunctionComponent } from 'react';
import HomeContent from './HomeContent';

const Home: FunctionComponent = () => {

  return (
    <div
      style={{
        width: '100%',
        background: '#f3f3f3',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'start',
      }}
    >
      <Box
        flex
        direction="column"
        align="start"
        justify="center"
        alignSelf="center"
        animation="fadeIn"
        pad={{
          left: 'large',
          right: 'large',
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <HomeContent />
      </Box>
    </div>
  );
};

export default Home;
