/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grommet } from 'grommet';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './styles/index.css';
import Theme from './theme';
import CommonHeader from './components/shared/CommonHeader';
import Home from './components/homepage/Home';
import GuardedRoute from './components/shared/GuardedRoute';
import Login from './components/auth/Login';
import CommonFooter from './components/shared/CommonFooter';
import CreateEvent from './components/homepage/event/CreateEvent';
import PastEvents from './components/homepage/event/PastEvents';
import { isLoggedIn, LOGIN_CHECK_INTERVAL, logout } from './services/authService';




function App(): JSX.Element {

  useEffect(() => { 
    const isMounted = true;
    if(isMounted) {
      setInterval(() => {
        const login = isLoggedIn();
        if(!login) logout();
      }, LOGIN_CHECK_INTERVAL)
    }
  }, []);

  return (
    <Router>
      <Grommet theme={Theme} className="root-container">
        <Switch>
          <GuardedRoute path="/backoffice/createEvent" component={CreateEventContainer} />
          <GuardedRoute path="/backoffice/pastEvents" component={PastEventsContainer} />
          <GuardedRoute path="/backoffice" component={HomeContainer} />
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Grommet>
    </Router>
  );
}


const HomeContainer = () => {
  return <>
    <CommonHeader />
    <Home />
    <CommonFooter />
  </>
}
const CreateEventContainer = () => {
  return <>
    <CommonHeader />
    <CreateEvent />
    <CommonFooter />
  </>
}
const PastEventsContainer = () => {
  return <>
    <CommonHeader />
    <PastEvents />
    <CommonFooter />
  </>
}



export default App;
