import { Box } from 'grommet';
import React, { FunctionComponent } from 'react';
// import Companies from '../companies/Companies';
// import Criteria from '../criteria/Criteria';
import Thematics from './Thematics';

const ThematicsGlass: FunctionComponent<{ englishAvailable: boolean }> = ({ englishAvailable }) => {
  return <Box fill>
    <Thematics glass englishAvailable={englishAvailable} />
  </Box>
};

export default ThematicsGlass;