/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { Box, Button, DropButton, Heading, ResponsiveContext } from "grommet";
import { AddCircle, Clock, Close, Configure, Download, Group, Save, Trash } from 'grommet-icons';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import EventInformation from './EventInformation';
import EventParameters from './EventParameters';
import EventImages from './EventImages';
import PexeEvent from '../../../entities/event.interface';
import BackofficeService from '../../../services/backofficeService';
import ConfirmModal from '../../shared/ConfirmModal';

type EventProps = {
  event: PexeEvent,
  setEvent: any,
  loading: boolean,
  setLoading: any,
  createMode?: boolean,
  editMode?: boolean,
}
const Event: FunctionComponent<EventProps> = ({ event, setEvent, loading, setLoading, createMode = false, editMode = false }) => {

  const history = useHistory();
  const size = useContext(ResponsiveContext);
  const { register, getValues, formState, control } = useForm({ mode: 'all' });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionDelete, setDeleteAction] = useState<(confirmed: boolean) => Promise<void>>(() => Promise.resolve());

  const saveEventChanges = async () => {
    setLoading(true);
    if (createMode) {
      const newEvent = { ...getValues() };
      setEvent(newEvent);
    } else {
      const eventValues = { ...event, ...getValues() };
      const updatedEvent = await BackofficeService.saveEvent(eventValues);
      if (updatedEvent) {
        setEvent(updatedEvent);
      }
    }
    setLoading(false);
  };


  const resultsFileDownload = async () => {
    setLoading(true);
    await BackofficeService.downloadResultsByEvent(event);
    setLoading(false);
  }

  const closeConfirm = () => {
    setShowConfirmModal(false);
    setDeleteAction(() => Promise.resolve());
  }

  const setDeleteProxy = (fn: any) => {
    setShowConfirmModal(true);
    setDeleteAction(() => fn());
  }

  const deleteEvent = () => async (confirmed: boolean) => {
    closeConfirm();
    if (confirmed) {
      setLoading(true);
      const isEventDeleted = await BackofficeService.deleteEvent(event.id);
      if (isEventDeleted) {
        setEvent(null);
      }
      setLoading(false);
    }
  }
  const deleteEventRates = () => async (confirmed: boolean) => {
    closeConfirm();
    if (confirmed) {
      setLoading(true);
      await BackofficeService.deleteEventResults();
      setLoading(false);
    }
  }
  const downloadJudgesList = async () => {
    setLoading(true);
    await BackofficeService.downloadJudgesSubscriptionList(event);
    setLoading(false);
  }

  const setEventAsCurrent = async (pexeEvent: PexeEvent) => {
    setLoading(true);
    pexeEvent.current = true;
    pexeEvent.finished = false;
    const updatedEvent = await BackofficeService.setEventAsCurrent(pexeEvent);
    if (updatedEvent) {
      history.push('/backoffice');
    }
    setLoading(false);
  }

  const modifyPublish = async () => {
    setLoading(true);
    event.finished = !event.finished;
    const updatedEvent = await BackofficeService.saveEvent(event);
    if (updatedEvent) {
      setEvent(updatedEvent);
    }
    setLoading(false);
  }


  return <Box flex direction="column" background="white" margin={{ vertical: "medium" }}>
    {showConfirmModal && <ConfirmModal confirmAction={actionDelete} closeAction={closeConfirm} />}
    <Box flex direction="column" fill elevation="small" pad="large">
      {!createMode ?
        <Box flex direction={size === "small" ? "column" : "row"} justify="between" align={size === "small" ? "center" : "start"} margin={{ vertical: "medium" }}>
          <Heading level="3" color="pexeGreen" margin={{ bottom: "10px" }}>
            <Clock style={{ marginRight: "10px", verticalAlign: "center" }} />
          Événement courant
          </Heading>

          <Box
            flex direction={size === "small" ? "column" : "row"}
            fill
            justify="end" align={size === "small" ? "center" : "end"} >

            <DropButton
              icon={<Configure />}
              color="pexeGreen"
              margin={{ bottom: "10px" }}
              label="Actions"
              dropAlign={{ top: 'bottom', right: size === 'small' ? 'right' : 'left' }}
              dropContent={
                <>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    !createMode && !editMode ?
                      event.finished ?
                        <Button
                          label="Retirer la publication de l'événement"
                          type="button"
                          disabled={loading}
                          margin={{ vertical: "small", horizontal: "small" }}
                          onClick={() => modifyPublish()}
                          color="status-warning"
                          icon={<Close />}
                        /> :
                        <Button
                          label="Publier l'événement"
                          type="button"
                          disabled={loading}
                          margin={{ vertical: "small", horizontal: "small" }}
                          onClick={() => modifyPublish()}
                          color="pexeGreen"
                          icon={<Group />}
                        />
                      : <Button
                        label="Définir comme événement courant"
                        type="button"
                        disabled={loading}
                        margin={{ vertical: "small", horizontal: "small" }}
                        onClick={() => setEventAsCurrent(event)}
                        color="pexeGreen"
                        icon={<Group />}
                      />
                  }
                  <Button
                    label="Supprimer l'événement"
                    type="button"
                    disabled={loading}
                    onClick={() => setDeleteProxy(deleteEvent)}
                    margin={{ vertical: "small", horizontal: "small" }}
                    color="status-error"
                    icon={<Trash />}
                  />
                  <Button
                    label="Supprimer les notes"
                    margin={{ vertical: "small", horizontal: "small" }}
                    type="button"
                    disabled={loading}
                    onClick={() => setDeleteProxy(deleteEventRates)}
                    color="status-error"
                    icon={<Trash />} />
                  <Button
                    label="Liste des juges souhaitant être recontactés"
                    margin={{ vertical: "small", horizontal: "small" }}
                    type="button"
                    disabled={loading}
                    onClick={() => downloadJudgesList()}
                    color="status-dark"
                    icon={<Download />} />
                </>
              }
            />


          </Box>
        </Box>
        : null
      }

      <EventInformation event={event} register={register} control={control} />
      <EventParameters event={event} register={register} setEvent={setEvent} createMode={createMode} resultsFileDownload={resultsFileDownload} />

      <Button
        disabled={!formState.isValid || loading}
        alignSelf="center"
        icon={createMode ? <AddCircle /> : <Save />}
        type="button"
        margin="small"
        label={createMode ? "Créer l'événement" : "Enregistrer les modifications"}
        onClick={() => saveEventChanges()}
        color="pexeGreen" />

      {!createMode && !editMode && <EventImages />}
    </Box>

  </Box>
}

export default Event;