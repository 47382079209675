/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Form,
  Heading,
  Layer,
  ResponsiveContext,
  Select,
  TextInput
} from "grommet";
import {Trash, UserExpert} from 'grommet-icons';
import {Controller, useForm} from 'react-hook-form';
import {Thematic} from '../../../entities/thematic.interface';
import BackofficeService from '../../../services/backofficeService';
import {Company} from '../../../entities/company.interface';
import CompanyComponent from './Company';
import ConfirmModal from '../../shared/ConfirmModal';

const Companies: FunctionComponent<{ glass: boolean, englishAvailable: boolean }> = ({glass, englishAvailable}) => {

  const size = useContext(ResponsiveContext);
  const [showAddForm, setVisibleForm] = useState(false);
  const {register, getValues, formState, control, reset} = useForm({mode: "all"});

  const [companies, setCompanies] = useState<Company[]>([]);

  const [thematics, setThematics] = useState<Thematic[]>([]);

  const [selectedThematic, setSelectedThematic] = useState(thematics && thematics.length > 0 ? thematics[0] : null);
  const [selectedThematicId, setSelectedThematicId] = useState<number | null>();

  const [actionStarted, setActionStarted] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionDelete, setDeleteAction] = useState<(confirmed: boolean) => Promise<void>>(() => Promise.resolve());

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (glass) {
        const retrievedThematics = await BackofficeService.retrieveThematics();
        if (isMounted) {
          setThematics(retrievedThematics);
          if (thematics.length > 0) {
            setSelectedThematic(retrievedThematics[0]);
          }
        }
      }
      if (isMounted) {
        const retrievedCompanies = await BackofficeService.retrieveAllCompanies();
        setCompanies(retrievedCompanies);
      }
    })();
    return () => {
      isMounted = false
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addCompany = async () => {
    if (!glass) {
      await addGlobalCompany();
    } else {
      await addCompanyByTheme();
    }
  }

  const updateCompany = async (company: Company) => {
    setActionStarted(true);
    await BackofficeService.updateCompany(company);
    setActionStarted(false);
  }


  const closeConfirm = () => {
    setShowConfirmModal(false);
    setDeleteAction(() => Promise.resolve());
  }

  const setProxyDelete = (id: number): void => {
    setShowConfirmModal(true);
    setDeleteAction(() => deleteCompany(id))
  }

  const deleteCompany = (id: number) => {
    // eslint-disable-next-line func-names
    return async function (confirmed: boolean) {
      closeConfirm();
      if (confirmed && id) {
        setActionStarted(true);
        setCompanies(companies.filter(c => c.id !== id));
        await BackofficeService.deleteCompany(id);
        setActionStarted(false);
      }
    }
  }

  const addCompanyByTheme = async () => {
    setActionStarted(true);
    const {thematic, ...company} = getValues();
    if (selectedThematicId) {
      const createdCompany = await BackofficeService.createCompanyByTheme(company as Company, selectedThematicId);
      if (createdCompany) {
        reset();
        setCompanies([...companies, createdCompany]);
        setVisibleForm(false);
      }
    }
    setActionStarted(false);
  }

  const addGlobalCompany = async () => {
    setActionStarted(true);
    const {thematic, ...company} = getValues();
    const createdCompany = await BackofficeService.createCompany(company as Company);
    if (createdCompany) {
      reset();
      setCompanies([...companies, createdCompany]);
      setVisibleForm(false);
    }
    setActionStarted(false);
  }

  const updateCompanyOrder = async (newValue: string, index: number) => {
    setActionStarted(true);

    const orderUpdateCompany = companies[index];

    const newOrderCompanies = companies.filter((element) => {
      return element.id !== orderUpdateCompany.id;
    });

    newOrderCompanies.splice(Number(newValue) - 1, 0, orderUpdateCompany);

    const orderUpdated = await BackofficeService.updateCompaniesOrder(newOrderCompanies);
    if (orderUpdated) {
      setCompanies(newOrderCompanies);
    }

    setActionStarted(false);
  }


  return <Box fill background="white" margin={{vertical: "medium"}}>

    <Box flex direction="column" fill elevation="small" pad="large">
      <Box flex direction="row" justify="between" align="center">
        <Heading level="3" color="pexeGreen" margin={{top: "0"}}>
          <UserExpert style={{marginRight: "10px", verticalAlign: "bottom"}}/>
          Entités ajoutées
        </Heading>
        <Button label="Ajouter une entité" color="pexeGreen" type="button"
                onClick={() => setVisibleForm(!showAddForm)}/>
      </Box>

      {showAddForm &&
          <Form>

            {selectedThematic || !glass ?
              <Box fill pad="large" direction={size !== "large" ? "column" : "row"} align="center" justify="around">
                <Box width="medium">
                  {
                    glass && selectedThematic &&
                      <Controller
                          name="thematic"
                          control={control}
                          defaultValue={selectedThematic.themesNameFr}
                          render={() => (
                            <Select
                              margin="7px"
                              onChange={({option}) => {
                                setSelectedThematic(option.themesNameFr);
                                setSelectedThematicId(option.id);
                              }}
                              options={thematics}
                              labelKey="themesNameFr"
                              value={selectedThematic.themesNameFr}/>
                          )}
                      />
                  }
                  <TextInput
                    style={{margin: "7px"}}
                    name="companyName" ref={register({required: true})}
                    placeholder="Nom de la nouvelle entité"/>
                  <TextInput
                    style={{margin: "7px"}}
                    name="textFr"
                    ref={register()}
                    placeholder="[FRANCAIS] Sujet d'intervention de l'entité"/>
                  {
                    englishAvailable
                    && <TextInput
                          style={{margin: "7px"}}
                          name="textEn"
                          ref={register()}
                          placeholder="[ANGLAIS] Sujet d'intervention de l'entité"/>
                  }

                  <Heading level="4">
                    Fiche de contact
                  </Heading>

                  <TextInput
                    style={{margin: "7px"}}
                    name="speakerFirstName"
                    ref={register()}
                    placeholder="Prénom de l'intervenant"/>
                  <TextInput
                    style={{margin: "7px"}}
                    name="speakerLastName"
                    ref={register()}
                    placeholder="Nom de l'intervenant"/>
                  <TextInput
                    style={{margin: "7px"}}
                    name="speakerEmail"
                    ref={register(
                      {
                        pattern: {
                          message: "",
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                        }
                      }
                    )}
                    placeholder="Adresse mail"/>
                  <TextInput
                    style={{margin: "7px"}}
                    name="speakerPhone"
                    ref={register(
                      {
                        pattern: {
                          message: "",
                          value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
                        }
                      }
                    )
                    }
                    placeholder="Numéro de téléphone"/>
                </Box>

                {
                  glass ?
                    <Button
                      type="button"
                      margin="medium"
                      disabled={(!formState.isValid && selectedThematicId === null) || actionStarted}
                      onClick={() => addCompany()}
                      label="Enregistrer la nouvelle entité"
                      secondary color="pexeGreen"/>
                    :
                    <Button
                      type="button"
                      margin="medium"
                      disabled={!formState.isValid || actionStarted}
                      onClick={() => addCompany()}
                      label="Enregistrer la nouvelle entité"
                      secondary color="pexeGreen"/>
                }


              </Box>
              : <Heading fill color="dark-3" textAlign="center" level="4"> Ajoutez un thème avant de pouvoir ajouter des
                entités. </Heading>
            }
          </Form>
      }

      {showConfirmModal && <ConfirmModal confirmAction={actionDelete} closeAction={closeConfirm}/>}

      <Accordion pad="medium">
        {
          companies && companies.length > 0 ?
            companies.map((company, index) => {
              return <Box pad="medium" key={company.id} flex justify="evenly" align="center"
                          direction={size === 'small' ? 'column' : 'row'}>
                <Select
                  disabled={actionStarted}
                  alignSelf="center"
                  size="small"
                  margin={{right: "10px"}}
                  options={[...companies.map((c, idx) => String(idx + 1))]}
                  value={String(index + 1)}
                  onChange={({option}) => updateCompanyOrder(option, index)}
                />
                <AccordionPanel style={{minWidth: size === 'small' ? '300px' : '500px'}} key={company.id + (index + 1)}
                                label={company.companyName}>
                  <CompanyComponent
                    englishAvailable={englishAvailable}
                    deleteCompany={(id: number) => setProxyDelete(id)}
                    updateCompany={updateCompany}
                    actionStarted={actionStarted}
                    key={company.id}
                    company={company}/>
                </AccordionPanel>
              </ Box>
            })
            : <Heading fill textAlign="center" level="4"> Aucune entité ajoutée pour le moment </Heading>
        }
      </Accordion>


    </Box>
  </Box>
}

export default Companies;
