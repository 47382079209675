/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet";
import { Workshop } from 'grommet-icons';
import { useForm } from 'react-hook-form';
import BackofficeService from '../../../services/backofficeService';
import { Thematic } from '../../../entities/thematic.interface';
import ThematicComponent from './Thematic';
import ThematicGlassComponent from './ThematicGlass';

const Thematics: FunctionComponent<{ glass: boolean, englishAvailable: boolean }> = ({ glass, englishAvailable }) => {

  const size = useContext(ResponsiveContext);

  const [showAddForm, setVisibleForm] = useState(false);
  const [thematics, setThematics] = useState<Thematic[]>([]);
  const [actionStarted, setActionStarted] = useState(false);
  const { register, getValues, formState, reset } = useForm({ mode: 'all' });

  const addThematic = async () => {
    // eslint-disable-next-line no-console

    setActionStarted(true);
    const thematic: Partial<Thematic> = {
      ...getValues()
    }

    const newThematic = await BackofficeService.createThematic(thematic);
    if (newThematic) {
      reset();
      setThematics([...thematics, newThematic]);
      setVisibleForm(false);
    }

    setActionStarted(false);
  }

  const modifyThematic = async (thematic: Thematic) => {
    const updatedThematic = await BackofficeService.updateThematic(thematic);
    if (updatedThematic) {
      const updatedThematics = thematics.map(t => {
        if (t.id === thematic.id) {
          t = { ...t, ...updatedThematic }
        }
        return t;
      });

      setThematics(updatedThematics);
    }
  };

  const removeThematic = async (id: number) => {
    setThematics(thematics.filter(th => th.id !== id));
    await BackofficeService.deleteThematic(id);
  };

  useEffect(() => {
    let isMounted = true;
    BackofficeService.retrieveThematics()
      .then(retrievedThematics => {
        if (isMounted) {
          setThematics(retrievedThematics)
        }
      });
    return () => { isMounted = false };
  }, [])

  return <Box fill background="white" margin={{ vertical: "medium" }}>

    <Box flex direction="column" fill elevation="small" pad="large">
      <Box flex direction="row" justify="between" align="center">
        <Heading level="3" color="pexeGreen" margin={{ top: "0" }}>
          <Workshop style={{ marginRight: "10px", verticalAlign: "bottom" }} />
            Vitrines ajoutées
        </Heading>
        <Button type="button" label="Ajouter une Vitrine" color="pexeGreen" onClick={() => setVisibleForm(!showAddForm)} />
      </Box>

      {showAddForm &&
        <Form>
          <Box fill pad="large" direction={size !== "large" ? "column" : "row"} align="center" justify="around">
            <Box width="medium" >
              <TextInput style={{ margin: "7px" }}
                name="themesNameFr"
                ref={register({ required: true })}
                placeholder="[FRANCAIS] Intitulé de la nouvelle Vitrine" />

              {englishAvailable &&
                <TextInput style={{ margin: "7px" }}
                  name="themeNameEn"
                  ref={register({ required: true })}
                  placeholder="[ANGLAIS] Intitulé de la nouvelle Vitrine" />
              }

            </Box>

            <Button
              type="button"
              margin={{ top: "10px" }}
              disabled={!formState.isValid || actionStarted}
              onClick={() => addThematic()}
              label="Enregistrer la nouvelle Vitrine"
              secondary color="pexeGreen" />
          </Box>
        </Form>
      }

      {
        thematics && thematics.length > 0 ?

          thematics.map(thematic => {
            if (glass) {
              return <ThematicGlassComponent
                englishAvailable={englishAvailable}
                key={thematic.id}
                thematic={thematic}
                actionStarted={actionStarted}
                setActionStarted={setActionStarted}
                removeThematic={removeThematic}
                modifyThematic={modifyThematic} />
            }
            return <ThematicComponent
              englishAvailable={englishAvailable}
              key={thematic.id}
              thematic={thematic}
              actionStarted={actionStarted}
              setActionStarted={setActionStarted}
              removeThematic={removeThematic}
              modifyThematic={modifyThematic}
            />
          })

          : <Heading fill textAlign="center" level="4"> Aucune Vitrine ajoutée pour le moment. </Heading>
      }
    </Box>
  </Box>

};

export default Thematics;