import { Anchor, Box, Button, Heading, Layer, Nav, ResponsiveContext, TextInput } from 'grommet';
import { Link, Lock } from 'grommet-icons';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import backofficeService from '../../services/backofficeService';



const CommonFooter = (): JSX.Element => {

  const size = useContext(ResponsiveContext);
  const [show, setShow] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { register, getValues, formState, reset } = useForm({ mode: 'all'} );

  const changePassword = async() => {
    const { password, confirmPassword } = getValues();
    setLoading(true);
    setError(false);
    let modifySuccess = false;
    if (password === confirmPassword)
      modifySuccess = await backofficeService.changePassword(password);
    else
      setError(true);

    if(modifySuccess) {
      setModified(true);
      reset();
    } else {
      setModified(false);
    }
    setLoading(false);
  }

  return (
    <Box
      as="header"
      direction="row"
      elevation="small"
      align="center"
      justify="between"
      style={{height: size !== "small" ? "130px" : "100px"}}
      pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    >
      <Nav direction="row" animation="fadeIn" flex justify="center">
        <Box flex direction="row" alignContent="center" justify="center">
          <Anchor
            color="lightDark"
            weight="normal"
            href={process.env.REACT_APP_FRONTOFFICE_URL}
            icon={<Link href={process.env.REACT_APP_FRONTOFFICE_URL} color="pexeGreen" />}
            alignSelf="center"
            label={size !== 'small' ? "Accéder à l'interface utilisateur" : 'Front-Office'}
          />
          <Anchor
            color="lightDark"
            weight="normal"
            icon={<Lock color="pexeGreen" />}
            href="#"
            onClick={() => setShow(true)}
            margin={{ left: size !== 'small' ? 'large' : 'small' }}
            alignSelf="center"
            label={size !== 'small' ? 'Modifier le mot de passe' : 'Mot de passe'}
          />
        </Box>
      </Nav>

      {show && (
        <Layer modal responsive style={{width: "400px", minHeight: "200px", padding: "25px"}}
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box fill flex height="fill" direction="column" align="stretch">

            {
              (modified || error) &&
              <Heading textAlign="center" level="4" color={modified ? "status-ok" : "status-error"}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {modified ? "Modifié avec succès" :  error ? "Les deux mots de passe doivent être identiques" : "Une erreur est survenue lors de la modification"}
              </Heading>
            }


            <Heading level="3" textAlign="center" margin={{bottom: "0"}}> Modification du mot de passe </Heading>
            <Box flex direction="column" justify="center" align="stretch" pad="medium">
              <TextInput
                name="password"
                type="password"
                ref={register({required: true})}
                placeholder="Nouveau mot de passe"
                icon={<Lock />}
                style={{margin: "10px 0 10px 0"}}
              />
              <TextInput
                name="confirmPassword"
                type="password"
                ref={register({required: true})}
                placeholder="Confirmer le mot de passe"
                icon={<Lock />}
                style={{margin: "10px 0 10px 0"}}
              />
            </Box>
            <Box flex direction="row" justify="around" align="end">
              <Button type="button" disabled={!formState.isValid || loading} label="Modifier" color="status-ok" onClick={() => changePassword()} />
              <Button type="button" disabled={loading} label="Fermer" color="status-error" onClick={() => setShow(false)} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>

  );
};

export default CommonFooter;

