/* eslint-disable no-empty */
import axios from 'axios';
import { Company } from '../entities/company.interface';
import { Criterion } from '../entities/criterion.interface';
import PexeEvent from '../entities/event.interface';
import { Thematic } from '../entities/thematic.interface';
import { getToken } from './authService';

const PUBLIC_URL_API = `${process.env.REACT_APP_API_URL}/public`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ADMIN_URL_API = `${process.env.REACT_APP_API_URL}/secure`;


async function retrieveCurrentEvent(): Promise<PexeEvent | null> {
  try {
    const { data } = await axios.get(`${PUBLIC_URL_API}/events`);
    return data;
  } catch {
    return null;
  }
}

async function setImage(file: File, type: 'header' | 'footer'): Promise<void> {
  try {
    const jwt = getToken();
    const formData = new FormData();
    formData.append('file', file);
    await axios.post(`${ADMIN_URL_API}/images/${type}`, formData, {
      timeout: 6000,
      headers: {
        "Authorization": `Bearer ${jwt}`,
        'Content-Type': 'multipart/form-data'
      },

    });
  } catch { }
}

async function getFooterImage(): Promise<string | null> {
  try {
    const { data: imageFooter } = await axios.get(`${PUBLIC_URL_API}/images/footer`, { timeout: 6000 });
    return imageFooter;
  } catch {
    return null;
  }
}

async function getHeaderImage(): Promise<string | null> {
  try {
    const { data: imageHeader } = await axios.get(`${PUBLIC_URL_API}/images/header`, { timeout: 6000 });
    return imageHeader;
  } catch {
    return null;
  }
}

async function saveEvent(event: PexeEvent): Promise<PexeEvent | null> {
  try {

    const jwt = getToken();

    const { data } = await axios.put(`${ADMIN_URL_API}/events/${event.id}`, event,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )

    return data;
  } catch {
    return null;
  }
}


async function createEvent(event: PexeEvent): Promise<PexeEvent | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/events`, event,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function deleteEvent(id: number): Promise<boolean> {
  try {
    const jwt = getToken();

    await axios.delete(`${ADMIN_URL_API}/events/${id}`,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return true;
  } catch {
    return false;
  }
}

async function setEventAsCurrent(newCurrentEvent: PexeEvent): Promise<PexeEvent | null> {
  try {
    const currentEvent = await retrieveCurrentEvent();
    if (currentEvent) {
      currentEvent.current = false;
      currentEvent.finished = true;
      await saveEvent(currentEvent);
    }
    const newCurrentEventPersisted = await saveEvent(newCurrentEvent);
    return newCurrentEventPersisted;
  } catch {
    return null;
  }
}


async function retrievePastEvents(): Promise<PexeEvent[]> {
  try {
    const jwt = getToken();

    const { data } = await axios.get(`${PUBLIC_URL_API}/events/past`,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return [];
  }
}

async function retrieveAllCriteria() {
  try {
    const { data } = await axios.get(`${PUBLIC_URL_API}/criterias`,
      { timeout: 6000 }
    );
    return data;
  } catch {
    return null;
  }
}

async function retrieveCriteriaByThematic(thematicId: number): Promise<Criterion[]> {
  try {
    const { data } = await axios.get(`${PUBLIC_URL_API}/criterias/${thematicId}`,
      { timeout: 6000 }
    );
    return data;
  } catch {
    return [];
  }
}

async function createCriterion(criterion: Criterion): Promise<Criterion | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/criterias`, criterion,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function createCriterionByTheme(criterion: Criterion, themeId: number): Promise<Criterion | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/criterias/${themeId}`, criterion,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function updateCriterion(criterion: Criterion): Promise<Criterion | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.put(`${ADMIN_URL_API}/criterias/${criterion.id}`, criterion,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function deleteCriterion(criterionId: number): Promise<void> {
  try {
    const jwt = getToken();

    await axios.delete(`${ADMIN_URL_API}/criterias/${criterionId}`,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
  } catch { }
}


async function createThematic(thematic: Partial<Thematic>): Promise<Thematic | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/themes`, thematic,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );

    return data;
  } catch {
    return null;
  }
}

async function updateThematic(thematic: Thematic): Promise<Thematic | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.put(`${ADMIN_URL_API}/themes/${thematic.id}`, thematic,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );

    return data;
  } catch {
    return null;
  }
}

async function deleteThematic(thematicId: number): Promise<void> {
  try {
    const jwt = getToken();

    await axios.delete(`${ADMIN_URL_API}/themes/${thematicId}`, {
      timeout: 6000,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
  } catch { }
}

async function retrieveThematics(): Promise<Thematic[]> {
  try {
    const jwt = getToken();

    const { data } = await axios.get(`${PUBLIC_URL_API}/themes`,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );

    return data;
  } catch {
    return [];
  }
}

async function retrieveAllCompanies(): Promise<Company[]> {
  try {
    const { data } = await axios.get(`${PUBLIC_URL_API}/companies`, { timeout: 6000 });
    return data;
  } catch {
    return [];
  }
}

async function updateCompaniesOrder(companies: Company[]): Promise<boolean> {
  try {
    const jwt = getToken();
    await axios.put(`${ADMIN_URL_API}/companies/order`, companies, {
      timeout: 6000,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    return true;
  } catch {
    return false;
  }
}

async function retrieveCompaniesByThematic(themeId: number): Promise<Company[]> {
  try {
    const { data } = await axios.get(`${PUBLIC_URL_API}/companies/${themeId}`, { timeout: 6000 });
    return data;
  } catch {
    return [];
  }
}

async function createCompany(company: Company): Promise<Company | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/companies`, company,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function createCompanyByTheme(company: Company, themeId: number): Promise<Company | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.post(`${ADMIN_URL_API}/companies/${themeId}`, company,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function updateCompany(company: Company): Promise<Company | null> {
  try {
    const jwt = getToken();

    const { data } = await axios.put(`${ADMIN_URL_API}/companies/${company.id}`, company,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return data;
  } catch {
    return null;
  }
}

async function deleteCompany(companyId: number): Promise<void> {
  try {
    const jwt = getToken();

    await axios.delete(`${ADMIN_URL_API}/companies/${companyId}`,
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
  } catch { }
}


async function deleteEventResults(): Promise<boolean> {
  try {
    const jwt = getToken();

    await axios.delete(`${ADMIN_URL_API}/rates`,
      {
        timeout: 3000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    return true;
  } catch {
    return false;
  }
}

async function downloadJudgesSubscriptionList(event: PexeEvent): Promise<any> {
  try {
    const jwt = getToken();

    const { data } = await axios.get(`${ADMIN_URL_API}/export/follow/${event.id}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${event.eventName}.contact.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch { }
}

async function downloadResultsByEvent(event: PexeEvent): Promise<any> {
  try {
    const jwt = getToken();

    const { data } = await axios.get(`${ADMIN_URL_API}/export/results/${event.id}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${event.eventName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch { }
}

async function changePassword(password: string): Promise<boolean> {
  try {
    const jwt = getToken();

    await axios.put(`${ADMIN_URL_API}/admin`, { password },
      {
        timeout: 6000,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
    return true;
  } catch {
    return false;
  }
}

const backofficeService = Object.freeze({
  createEvent,
  saveEvent,
  deleteEvent,
  setEventAsCurrent,
  retrievePastEvents,
  downloadResultsByEvent,
  retrieveCurrentEvent,
  downloadJudgesSubscriptionList,
  deleteEventResults,

  retrieveAllCriteria,
  retrieveCriteriaByThematic,
  createCriterion,
  createCriterionByTheme,
  updateCriterion,
  deleteCriterion,

  setImage,
  getFooterImage,
  getHeaderImage,

  retrieveThematics,
  createThematic,
  updateThematic,
  deleteThematic,
  changePassword,

  retrieveAllCompanies,
  updateCompaniesOrder,
  retrieveCompaniesByThematic,
  createCompany,
  createCompanyByTheme,
  updateCompany,
  deleteCompany
});

export default backofficeService;