/* eslint-disable func-names */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Form,
  Heading,
  ResponsiveContext,
  Select,
  Text,
  TextInput
} from "grommet"
import { useForm } from 'react-hook-form';
import { AddCircle, Alert, Edit, Save, Trash, UserAdd, UserExpert } from 'grommet-icons';
import { Thematic } from '../../../entities/thematic.interface';
import BackofficeService from '../../../services/backofficeService';
import { Company } from '../../../entities/company.interface';
import { Criterion } from '../../../entities/criterion.interface';
import CriterionComponent from '../criteria/Criterion';
import CompanyComponent from '../companies/Company';
import CompanyForm from '../companies/CompanyForm';
import CriterionForm from '../criteria/CriterionForm';
import ConfirmModal from '../../shared/ConfirmModal';

type ThematicsGlassTypes = {
  thematic: Thematic,
  removeThematic: any,
  englishAvailable: boolean,
  modifyThematic: any,
  setActionStarted: any,
  actionStarted: boolean,
};
const ThematicGlassComponent: FunctionComponent<ThematicsGlassTypes>
  = ({ thematic, removeThematic, modifyThematic, setActionStarted, actionStarted, englishAvailable }) => {

    const size = useContext(ResponsiveContext);
    const { register, getValues } = useForm({ mode: 'onChange' });

    const [showCompanyAddForm, setShowCompanyAddForm] = useState(false);
    const [showThematics, setShowThematics] = useState(false);
    const [showCriterionAddForm, setShowCriterionAddForm] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [criteria, setCriteria] = useState<Criterion[]>([]);
    const [criteriaPercentage, setCriteriaPercentage] = useState<number>(0);
    const [criterionFormWeight, setCriterionFormWeight] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [actionDelete, setDeleteAction] = useState<(confirmed: boolean) => Promise<void>>(() => Promise.resolve());

    const updateThematic = async () => {
      setActionStarted(true);
      const modifiedThematic = { ...thematic, ...getValues() };
      await modifyThematic(modifiedThematic);
      setActionStarted(false);
    }

    const setProxyDelete = (fn: any, ...param: any[]): void => {
      setShowConfirmModal(true);
      setDeleteAction(() => fn(...param))
    }

    const deleteThematic = () => {
      return async function (confirmed: boolean) {
        closeConfirm();
        if (confirmed) {
          setActionStarted(true);
          await removeThematic(thematic.id);
          setActionStarted(false);
        }
      }
    }

    const addCompanyByTheme = async (company: Company, themeId: number) => {
      setActionStarted(true);
      const addedCompany = await BackofficeService.createCompanyByTheme(company, themeId);
      if (addedCompany) {
        setCompanies([...companies, addedCompany]);
      }
      setActionStarted(false);
    }

    const updateCompany = async (company: Company) => {
      setActionStarted(true);
      await BackofficeService.updateCompany(company);
      setActionStarted(false);
    }

    const removeCompany = (id: number) => {
      return async function (confirmed: boolean) {
        closeConfirm();
        if (confirmed && id) {
          setActionStarted(true);
          setCompanies(companies.filter(c => c.id !== id));
          await BackofficeService.deleteCompany(id);
          setActionStarted(false);
        }
      }
    }

    const addCriterionByTheme = async (criterion: Criterion, themeId: number) => {
      setActionStarted(true);
      const addedCriterion = await BackofficeService.createCriterionByTheme(criterion, themeId);
      if (addedCriterion) {
        const newCriteria = [...criteria, addedCriterion];
        updateCriteriaWeight(newCriteria);
        setCriteria(newCriteria);
      }
      setActionStarted(false);
    }

    const updateCriterion = async (criterion: Criterion) => {
      setActionStarted(true);
      const updatedCriterion = await BackofficeService.updateCriterion(criterion);
      if (updatedCriterion) {
        const updatedCriteria = criteria.map(c => {
          if (c.id === criterion.id) {
            c.weight = criterion.weight;
          }
          return c;
        });

        setCriteria(updatedCriteria);
        updateCriteriaWeight(updatedCriteria);
      }
      setActionStarted(false);
    }

    const updateCriteriaWeight = (newCriteria: Criterion[] = criteria) => {
      const criteriaWeights = newCriteria.reduce((acc, val) => acc + val.weight, 0);
      const totalCriteriaWeight = criterionFormWeight + criteriaWeights;
      setCriteriaPercentage(totalCriteriaWeight);
    };


    const closeConfirm = () => {
      setShowConfirmModal(false);
      setDeleteAction(() => Promise.resolve());
    }

    const removeCriterion = (id: number) => {
      return async function (confirmed: boolean) {
        closeConfirm();
        if (confirmed && id) {
          setActionStarted(true);
          const filteredCriteria = criteria.filter(c => c.id !== id);
          setCriteria(filteredCriteria);
          updateCriteriaWeight(filteredCriteria);
          await BackofficeService.deleteCriterion(id);
          setActionStarted(false);
        }
      }
    }

    const updateCompanyOrder = async (newValue: string, index: number) => {
      setActionStarted(true);
      const orderUpdateCompany = companies[index];

      const newOrderCompanies = companies.filter((element) => {
        return element.id !== orderUpdateCompany.id;
      });

      newOrderCompanies.splice(Number(newValue) - 1, 0, orderUpdateCompany);

      const orderUpdated = await BackofficeService.updateCompaniesOrder(newOrderCompanies);
      if (orderUpdated) {
        setCompanies(newOrderCompanies);
      }

      setActionStarted(false);
    }

    useEffect(() => {
      const isMounted = true;
      (async () => {

        const [retrievedCompanies, retrievedCriteria] = await Promise.all([
          BackofficeService.retrieveCompaniesByThematic(thematic.id),
          BackofficeService.retrieveCriteriaByThematic(thematic.id)
        ]);

        if (isMounted) {
          if (retrievedCompanies) setCompanies(retrievedCompanies);
          if (retrievedCriteria) {
            setCriteria(retrievedCriteria);
            updateCriteriaWeight(retrievedCriteria);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCompanies, setCriteria, thematic]);

    return <Box fill flex direction="column">
      <Box flex justify="between" direction="row" align="center" pad="medium">
        <Heading level="3"> {thematic.themesNameFr} </Heading>

        <Button label="Modifier" color="status-dark" alignSelf="center" onClick={() => setShowThematics(!showThematics)} />
      </Box>

      {showConfirmModal && <ConfirmModal confirmAction={actionDelete} closeAction={closeConfirm} />}

      {showThematics &&
        <>
          <Form>
            <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="between">
              <Box width={size === "large" ? "large" : "medium"} flex direction="column">
                <TextInput name="themesNameFr" placeholder="[FRANCAIS] Nom de la vitrine" ref={register()} defaultValue={thematic.themesNameFr} style={{ margin: "7px" }} />
                {englishAvailable && <TextInput
                  placeholder="[ANGLAIS] Nom de la vitrine"
                  name="themeNameEn" ref={register()} defaultValue={thematic.themeNameEn} style={{ margin: "7px" }} />}
              </Box>

              <Box fill pad="small" flex direction="row" justify="center" align="center">
                <Button
                  disabled={actionStarted}
                  type="button"
                  margin={{ horizontal: "small" }}
                  icon={<Save />}
                  label="Enregistrer"
                  color="pexeGreen"
                  onClick={() => updateThematic()} />

                <Button
                  disabled={actionStarted}
                  type="button"
                  label="Supprimer"
                  style={{ opacity: actionStarted ? "0.3" : "1" }}
                  color="status-critical"
                  icon={<Trash />}
                  onClick={() => setProxyDelete(deleteThematic)} />
              </Box>
            </Box>
          </Form>
            <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="between">
                <Box width="large" flex direction="column">
                    <Text style={{ margin: "7px" }}>Nombre de téléchargements de la fiche contact: {thematic.countContactDownload ?? 0}</Text>
                </Box>
            </Box>

          <Accordion pad="large">
            <AccordionPanel label="Entités ajoutées">
              <Box pad="medium">
                <Heading level="3" color="pexeGreen" margin={{ horizontal: "0", top: "10px" }}>
                  <UserExpert style={{ marginRight: "10px", verticalAlign: "bottom" }} />
                Entités ajoutées
                </Heading>

                <Box flex justify="end" align="center" direction="row" margin={{ bottom: "small" }}>
                  <Button label="Ajouter une entité" color="dark-1" type="button" icon={<UserAdd />} onClick={() => setShowCompanyAddForm(!showCompanyAddForm)} />
                </Box>

                <CompanyForm
                  englishAvailable={englishAvailable}
                  thematic={thematic}
                  addCompanyByTheme={addCompanyByTheme}
                  actionStarted={actionStarted}
                  setShowAddForm={setShowCompanyAddForm}
                  showAddForm={showCompanyAddForm} />

                <Accordion margin={{ bottom: "35px" }}>
                  {
                    companies && companies.length > 0 ?

                      companies.map((company, index) => {
                        return <Box pad="medium" key={company.id} flex justify="evenly" align="center" direction={size === 'small' ? 'column' : 'row'}>
                          <Select
                            disabled={actionStarted}
                            alignSelf="center"
                            size="small"
                            margin={{ right: "10px" }}
                            options={[...companies.map((c, idx) => String(idx + 1))]}
                            value={String(index + 1)}
                            onChange={({ option }) => updateCompanyOrder(option, index)}
                          />
                          <AccordionPanel style={{ minWidth: size === 'small' ? '300px' : '500px' }} key={company.id + index} label={company.companyName}>
                            <CompanyComponent
                              englishAvailable={englishAvailable}
                              actionStarted={actionStarted}
                              updateCompany={updateCompany}
                              deleteCompany={(id: number) => setProxyDelete(removeCompany, id)}
                              key={company.id}
                              company={company} />
                          </AccordionPanel>
                        </ Box>
                      })

                      : <Heading level="4"> Aucune entreprise intervient sur cette Vitrine.</Heading>
                  }
                </Accordion>

              </Box>
            </AccordionPanel>

            <AccordionPanel label="Critères ajoutés">
              <Box pad="medium">
                <Heading level="3" color="pexeGreen" margin={{ top: "10px" }}>
                  <Edit style={{ marginRight: "10px", verticalAlign: "bottom" }} />
           Critères ajoutés
                </Heading>


                {criteriaPercentage !== 100 && criteria && criteria.length > 0 &&
                  <Heading level="4" fill responsive alignSelf="center"> <Alert color="status-error" /> <br />
            Le poids total des critères doit être strictement égal à 100%. <br /> Poids actuel : <b style={{ color: "red" }}>{criteriaPercentage}%</b>
                  </Heading>
                }


                <Box flex justify="end" align="center" direction="row" margin={{ bottom: "small" }}>
                  <Button type="button" label="Ajouter un critère" color="dark-1" icon={<AddCircle />} onClick={() => setShowCriterionAddForm(!showCriterionAddForm)} />
                </Box>

                <CriterionForm
                  updateCriteriaWeight={updateCriteriaWeight}
                  criteriaPercentage={criteriaPercentage}
                  criterionFormWeight={criterionFormWeight}
                  setCriterionFormWeight={setCriterionFormWeight}
                  englishAvailable={englishAvailable}
                  thematic={thematic}
                  actionStarted={actionStarted}
                  addCriterionByTheme={addCriterionByTheme}
                  setShowAddForm={setShowCriterionAddForm}
                  showAddForm={showCriterionAddForm} />

                <Accordion margin={{ bottom: "35px" }}>
                  {
                    criteria && criteria.length > 0 ?
                      criteria.map((criterion, index) => {
                        return <AccordionPanel key={criterion.id + index} label={criterion.criteriaFr}>
                          <CriterionComponent
                            englishAvailable={englishAvailable}
                            actionStarted={actionStarted}
                            updateCriterion={updateCriterion}
                            deleteCriterion={(id: number) => setProxyDelete(removeCriterion, id)}
                            criterion={criterion} key={criterion.id} />
                        </AccordionPanel>
                      })
                      : <Heading level="4"> Aucun critère associé à cette Vitrine.</Heading>
                  }

                </Accordion>

              </Box>
            </AccordionPanel>

          </Accordion>


        </>
      }

    </Box>
  }

export default ThematicGlassComponent;
