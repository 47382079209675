/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Accordion, AccordionPanel, Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet";
import { Alert, Save, Trash, UserExpert } from 'grommet-icons';
import { useForm } from 'react-hook-form';
import { Criterion } from '../../../entities/criterion.interface';
import BackofficeService from '../../../services/backofficeService';
import CriterionComponent from './Criterion';
import ConfirmModal from '../../shared/ConfirmModal';


const Criteria: FunctionComponent<{ glass: boolean, englishAvailable: boolean }> = ({ glass, englishAvailable }) => {

  const size = useContext(ResponsiveContext);
  const [criteria, setCriteria] = useState<Criterion[]>([]);
  const { register, getValues, reset, formState } = useForm({ mode: 'onChange' });
  const [showAddForm, setVisibleForm] = useState(false);
  const [actionStarted, setActionStarted] = useState(false);
  const [criteriaPercentage, setCriteriaPercentage] = useState<number>(0);
  const [formValue, setFormValue] = useState<number>(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionDelete, setDeleteAction] = useState<(confirmed: boolean) => Promise<void>>(() => Promise.resolve());

  const updateCriteriaWeight = (newCriteria: Criterion[] = criteria) => {
    const criteriaWeights = newCriteria.reduce((acc, val) => acc + val.weight, 0);
    // const totalCriteriaWeight = criterionFormWeight + criteriaWeights;
    setCriteriaPercentage(criteriaWeights);
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!glass) {
        const retrievedCriteria = await BackofficeService.retrieveAllCriteria();
        if (retrievedCriteria && isMounted) {
          setCriteria(retrievedCriteria);
          updateCriteriaWeight(retrievedCriteria);
        }
      }
    })();
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addGlobalCriterion = async () => {
    setActionStarted(true);
    const criterion = getValues();
    criterion.weight = parseInt(criterion.weight, 10);
    const createdCriterion = await BackofficeService.createCriterion(criterion as Criterion);
    if (createdCriterion) {
      reset();
      const newCriteria = [...criteria, createdCriterion];
      updateCriteriaWeight(newCriteria);
      setCriteria(newCriteria);
      setVisibleForm(false);
    }
    setActionStarted(false);
  }

  const closeConfirm = () => {
    setShowConfirmModal(false);
    setDeleteAction(() => Promise.resolve());
  }

  const setProxyDelete = (id: number): void => {
    setShowConfirmModal(true);
    setDeleteAction(() => deleteCriterion(id))
  }

  const deleteCriterion = (id: number) => {
    // eslint-disable-next-line func-names
    return async function (confirmed: boolean) {
      closeConfirm();
      if (confirmed && id) {
        setCriteria(criteria.filter(c => c.id !== id));
        const filteredCriteria = criteria.filter(c => c.id !== id);
        setCriteria(filteredCriteria);
        updateCriteriaWeight(filteredCriteria);
        await BackofficeService.deleteCriterion(id);
      }
    }
  }

  const updateCriterion = async (criterion: Criterion) => {
    setActionStarted(true);
    const updatedCriterion = await BackofficeService.updateCriterion(criterion);
    if (updatedCriterion) {
      const updatedCriteria = criteria.map(c => {
        if (c.id === criterion.id) {
          c.weight = criterion.weight;
        }
        return c;
      });

      setCriteria(updatedCriteria);
      updateCriteriaWeight(updatedCriteria);
    }
    setActionStarted(false);
  }


  return <Box fill>

    <Box flex direction="column" fill elevation="small" pad="large" background="white" margin={{ vertical: "medium" }}>
      <Box flex direction="row" justify="between" align="center">
        <Heading level="3" color="pexeGreen" margin={{ top: "0px" }}>
          <UserExpert style={{ marginRight: "10px", marginTop: "0px", verticalAlign: "bottom" }} />
            Critères ajoutés
        </Heading>
        <Button label="Ajouter un critère" type="button" color="pexeGreen" onClick={() => setVisibleForm(!showAddForm)} />
      </Box>


      {criteriaPercentage !== 100 && criteria && criteria.length > 0 &&
        <Heading level="4" fill responsive alignSelf="center"> <Alert color="status-error" /> <br />
            Le poids total des critères doit être strictement égal à 100%. <br /> Poids actuel : <b style={{ color: "red" }}>{criteriaPercentage}%</b>
        </Heading>
      }

      {showConfirmModal && <ConfirmModal confirmAction={actionDelete} closeAction={closeConfirm} />}


      {showAddForm &&

        <Form>
          {
            formValue + criteriaPercentage > 100 &&
            <Heading level="4" fill>
              Ce nouveau critère dépassera un poids de 100%.
            </Heading>
          }
          <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="around">
            <Box width="medium">
              <TextInput
                style={{ margin: "7px" }}
                type="text"
                name="criteriaFr" ref={register({ required: true })}
                placeholder="[FRANCAIS] Nom du critère" />
              {
                englishAvailable &&
                <TextInput
                  style={{ margin: "7px" }}
                  name="criteriaEn"
                  type="text"
                  ref={register({ required: true })}
                  placeholder="[ANGLAIS] Nom du critère" />
              }
              <TextInput
                style={{ margin: "7px" }}
                icon={<Heading level="3">%</Heading>}
                type="number"
                reverse
                min="0"
                max="100"
                onChange={(e) => setFormValue(Number.isInteger(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : 0)}
                name="weight"
                ref={
                  register(
                    {
                      required: true,
                      min: 0,
                      max: 100,
                      pattern: {
                        value: /^[1-9][0-9]?$|^100$/,
                        message: ""
                      }
                    }
                  )
                }
                placeholder="Poids du critère (%)" />
            </Box>

            <Button
              type="button"
              icon={<Save />}
              margin="medium"
              disabled={!formState.isValid || actionStarted}
              onClick={() => addGlobalCriterion()}
              label="Ajouter"
              secondary color="pexeGreen" />
          </Box>
        </Form>
      }

      <Accordion pad="medium">
        {
          criteria && criteria.length > 0 ?

            criteria.map((criterion, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <AccordionPanel key={criterion.id + (index + 1)} label={criterion.criteriaFr}>
                <CriterionComponent
                  englishAvailable={englishAvailable}
                  updateCriterion={updateCriterion}
                  actionStarted={actionStarted}
                  deleteCriterion={(id: number) => setProxyDelete(id)}
                  key={criterion.id}
                  criterion={criterion} />
              </AccordionPanel>
            })
            : <Heading fill textAlign="center" level="4"> Aucun critère ajouté pour le moment. </Heading>
        }
      </Accordion>



    </Box>
  </Box>
}

export default Criteria;