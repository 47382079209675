import { Box, Button, Heading, Layer } from 'grommet';
import React, { FunctionComponent } from 'react';

type ConfirmModal = {
    confirmMessage?: string,
    confirmAction: (confirmed: boolean) => void;
    closeAction: () => void;
}
const ConfirmModal: FunctionComponent<ConfirmModal> = ({ confirmMessage = 'Confirmer', confirmAction, closeAction }) => {

    const actionToDo = (confirmed: boolean) => confirmAction(confirmed);

    return <Layer modal responsive style={{ minWidth: "250px", minHeight: "150px", padding: "25px" }}
        onEsc={() => closeAction()}
        onClickOutside={() => closeAction()}
    >
        <Box fill flex height="fill" direction="column" align="stretch">
            <Heading level="3" textAlign="center" margin={{ bottom: "0" }}> {confirmMessage} </Heading>
            <Box flex direction="row" justify="around" align="end">
                <Button type="button" label="Oui" color="status-ok" onClick={() => actionToDo(true)} />
                <Button type="button" label="Non" color="status-error" onClick={() => actionToDo(false)} />
            </Box>
        </Box>
    </Layer>

}

export default ConfirmModal;