import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet"
import { useForm } from 'react-hook-form';
import { Trash } from 'grommet-icons';
import { Thematic } from '../../../entities/thematic.interface';
import BackofficeService from '../../../services/backofficeService';
import { Company } from '../../../entities/company.interface';

type ThematicProps = {
  thematic: Thematic,
  removeThematic: any,
  englishAvailable: boolean,
  modifyThematic: any,
  setActionStarted: any,
  actionStarted: boolean,
}
const ThematicComponent: FunctionComponent<ThematicProps>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  = ({ englishAvailable, thematic, modifyThematic, actionStarted, setActionStarted, removeThematic }) => {

    const size = useContext(ResponsiveContext);
    const { register, getValues } = useForm({ mode: 'onChange' });
    const [showThematics, setShowThematics] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);

    const updateThematic = async () => {
      setActionStarted(true);
      await modifyThematic(getValues());
      setActionStarted(false);
    }

    const deleteThematic = () => {
      // eslint-disable-next-line func-names
      // return async function (confirmed: boolean) {
      //   console.log(confirmed);
      //   if (confirmed) {
      //     setActionStarted(true);
      //     await removeThematic(thematic.id);
      //     setActionStarted(false);
      //   }
      // }
    }

    useEffect(() => {
      const isMounted = true;
      (async () => {
        if (isMounted) {
          const retrievedCompanies = await BackofficeService.retrieveCompaniesByThematic(thematic.id);
          if (retrievedCompanies) setCompanies(retrievedCompanies);
        }
      })();
    }, [thematic]);

    return <Box fill flex direction="column" pad="medium">

      <Box flex justify="between" direction="row" align="center">
        <Heading level="3"> {thematic.themesNameFr} </Heading>
        <Button label="Modifier" color="status-dark" alignSelf="center" onClick={() => setShowThematics(!showThematics)} />
      </Box>

      {
        showThematics &&
        <>
          <Form>
            <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="between">
              <Box width={size === "large" ? "large" : "medium"} flex direction="column">
                <TextInput name="themesNameFr"
                  placeholder="[FRANCAIS] Nom de la vitrine"
                  ref={register({ required: true })} defaultValue={thematic.themesNameFr} style={{ margin: "7px" }} />
                {
                  englishAvailable &&
                  <TextInput name="themeNameEn" placeholder="[ANGLAIS] Nom de la vitrine"
                    ref={register({ required: true })} defaultValue={thematic.themeNameEn} style={{ margin: "7px" }} />
                }
              </Box>

              <Box pad="small" flex direction="row" justify="around" align="center">
                <Button
                  type="button"
                  label="Enregistrer"
                  color="pexeGreen"
                  disabled={actionStarted}
                  onClick={() => updateThematic()} />
                <Button
                  type="button"
                  label="Supprimer"
                  style={{ opacity: actionStarted ? "0.3" : "1" }}
                  color="status-critical"
                  icon={<Trash />}
                  disabled={actionStarted}
                  onClick={() => deleteThematic()} />
              </Box>
            </Box>
          </Form>

          <Box pad="large">
            Liste des entreprises intervenant sur cette Vitrine :

          {
              companies && companies.length > 0 ?
                companies.map((company) => <Box key={company.id} flex direction="row" justify="center" align="stretch">
                  <Heading level="3"> [FRANCAIS] : {company.textFr} </Heading>
                  {
                    englishAvailable && <Heading level="3"> [ANGLAIS] : {company.textEn} </Heading>
                  }
                </Box>
                ) : <Heading level="4"> Aucune entreprise enregistrée sur cette Vitrine.</Heading>
            }
          </Box>
        </>
      }
    </Box>
  }

export default ThematicComponent;
