import { Box, Spinner } from 'grommet';
import React, { FunctionComponent } from 'react';

const PexeSpinner: FunctionComponent = () => {
  return <Box fill height="fill" flex align="center" justify="center">
    <Box align="center" direction="row" gap="small">
      <Spinner
        border={[
          {
            side: 'all',
            color: 'pexeGreen',
            size: 'xlarge',
            style: 'groove',
          },
        ]}
      />
    </Box>
  </Box>
};

export default PexeSpinner;