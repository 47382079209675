/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Heading, ResponsiveContext } from "grommet";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PexeEvent from "../../../entities/event.interface";
import BackofficeService from "../../../services/backofficeService";
import PexeSpinner from "../../shared/PexeSpinner";
import EventComponent from "./Event";


const memoForm: PexeEvent | null = null;

const CreateEvent: FunctionComponent = () => {

  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [event, setStaticEvent] = useState<PexeEvent>({
    id: 0,
    eventName: "",
    presentationTextEn: "",
    subPresentationTextEn: "",
    subPresentationTextFr: "",
    finished: false,
    presentationTextFr: "",
    current: true,
    date: new Date().toString(),
    en_available: false,
    glass: false,
    countContactDownload: null
  });

  const setEvent = async (newEvent: PexeEvent, persist = true) => {
    if (persist) {
      setSpinner(true);
      const newCurrentEvent = await BackofficeService.createEvent({ ...newEvent, finished: false, current: true });
      if (newCurrentEvent) {
        history.push("/backoffice");
      } else {
        setShowError(true);
      }
      setSpinner(false);
    } else {
      setStaticEvent({ ...event, ...newEvent })
    }
  };


  if (showSpinner) return <PexeSpinner />

  return <Box flex direction="column" background="#f3f3f3">
    <Box width={size === "large" ? "70%" : "95%"} margin="0 auto">
      <Box flex direction="column" fill pad="large">
        {
          showError && <Heading level="4" color="status-error"> Une erreur est survenue. Veuillez réessayer </Heading>

        }
        {
          event && <EventComponent createMode event={event} setEvent={setEvent} loading={loading} setLoading={setLoading} />
        }
      </Box>
    </Box>
  </Box>

}

export default CreateEvent;
