
import axios from "axios";

const secureURL = `${process.env.REACT_APP_API_URL}/secure`;
const LOGIN_CHECK_INTERVAL = 5000;

async function login(username: string, password: string): Promise<boolean> {

  try {
    const { data: { token } } = await axios.post(`${secureURL}/login_check`, { username, password });

    if(token) {
      localStorage.setItem("pexe-jwt", JSON.stringify(token));
      return true;
    }
  } catch {
    // ignore
    return false;
  }

  return false;
  
}

function getToken(): string {
  const jwt = localStorage.getItem("pexe-jwt") as string;
  return JSON.parse(jwt);
}

function isLoggedIn(): boolean {
  const jwt = localStorage.getItem("pexe-jwt");

  if(!jwt) return false;

  const { exp } = parseToken(jwt);
  const now = new Date().getTime();

  /* x1000 to convert Number of seconds to milliseconds */
  if(now > (exp * 1000)) {
    logout();
    return false;
  }
  return true;
}

function parseToken(jwt: string): { exp: number, iat: number } {
  const tokenData = JSON.parse(atob(jwt.split(".")[1]));
  return tokenData;
}


function logout(): void {
  localStorage.removeItem("pexe-jwt");
}


export { login, isLoggedIn, logout, getToken, LOGIN_CHECK_INTERVAL };