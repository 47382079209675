import React, { FunctionComponent, useContext } from 'react'
import { Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet";
import { useForm } from 'react-hook-form';
import { Save } from 'grommet-icons';
import { Thematic } from '../../../entities/thematic.interface';

type CompanyFormTypes = {
  showAddForm: any,
  thematic: Thematic,
  addCompanyByTheme: any,
  setShowAddForm: any,
  actionStarted: boolean,
  englishAvailable: boolean
}
const CompanyForm: FunctionComponent<CompanyFormTypes>
  = ({ showAddForm, thematic, addCompanyByTheme, setShowAddForm, actionStarted, englishAvailable }) => {

    const size = useContext(ResponsiveContext);
    const { register, formState, getValues } = useForm({ mode: 'all' });

    const addCompany = async () => {
      const newCompany = getValues();
      await addCompanyByTheme(newCompany, thematic.id);
      setShowAddForm(false);
    }

    if (showAddForm) {
      return <Form style={{ marginBottom: "30px" }}>
        <Box fill pad="medium" direction={size !== "large" ? "column" : "row"} align="center" justify="around">
          <Box width="medium">
            <TextInput
              style={{ margin: "7px" }}
              name="companyName" ref={register({ required: true })}
              placeholder="Nom de la nouvelle entité" />
            <TextInput
              style={{ margin: "7px" }}
              name="textFr"
              ref={register()}
              placeholder="[FRANCAIS] Sujet d'intervention de l'entité" />
            {
              englishAvailable &&
              <TextInput
                style={{ margin: "7px" }}
                name="textEn"
                ref={register()}
                placeholder="[ANGLAIS] Sujet d'intervention de l'entité" />
            }
            <Heading level="4">
              Fiche de contact
            </Heading>
            <TextInput
              style={{ margin: "7px" }}
              name="speakerFirstName"
              ref={register()}
              placeholder="Prénom de l'intervenant" />
            <TextInput
              style={{ margin: "7px" }}
              name="speakerLastName"
              ref={register()}
              placeholder="Nom de l'intervenant" />
            <TextInput
              style={{ margin: "7px" }}
              name="email"
              ref={register(
                {
                  pattern: {
                    message: "",
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                  }
                }
              )}
              placeholder="Adresse mail" />
            <TextInput
              style={{ margin: "7px" }}
              name="speakerPhone"
              ref= {register()}
              placeholder="Numéro de téléphone" />
          </Box>
          <Button
            icon={<Save />}
            margin="medium"
            type="button"
            disabled={!formState.isValid || actionStarted}
            onClick={() => addCompany()}
            label="Ajouter"
            secondary color="pexeGreen"
          />
        </Box>
      </Form>
    }

    return null;

  }

export default CompanyForm;
