import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isLoggedIn } from '../../services/authService';

type GuardDeps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FunctionComponent<any>;
} & RouteProps;
const GuardedRoute: FunctionComponent<GuardDeps> = ({
  component: Component,
  ...rest
}) => {

  const isAuth = isLoggedIn();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth === true ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default GuardedRoute;
