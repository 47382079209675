/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useContext, useState } from 'react'
import { Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet";
import { useForm } from 'react-hook-form';
import { Save } from 'grommet-icons';
import { Thematic } from '../../../entities/thematic.interface';

type CriterionFormProps = {
  showAddForm: any, 
  thematic: Thematic, 
  addCriterionByTheme: any, 
  actionStarted: boolean, 
  setShowAddForm: any,
  criteriaPercentage: number,
  criterionFormWeight: number,
  updateCriteriaWeight: any,
  setCriterionFormWeight: any,
  englishAvailable: boolean
}
const CriterionForm: FunctionComponent<CriterionFormProps> 
= ({ showAddForm, thematic, addCriterionByTheme, actionStarted, setShowAddForm, englishAvailable, updateCriteriaWeight, criterionFormWeight, criteriaPercentage, setCriterionFormWeight }) => {
  const size = useContext(ResponsiveContext);
  const { register, formState, getValues, reset } = useForm({mode: 'all'});


  const [criterionFormWeightValue, setCriterionFormWeightValue] = useState<number>(0);

  const addCriterion = async() => {
    const criterion = getValues();
    criterion.weight = parseInt(criterion.weight, 10);
    await addCriterionByTheme(criterion, thematic.id);
    setShowAddForm(false);
  }

  const setFormCriterionWeight = (val: number) => {
    setCriterionFormWeightValue(Number.isInteger(val) ? val : 0);
  }

  if(showAddForm) {
    return <Form style={{marginBottom: "30px"}}>
      
      {
        criterionFormWeightValue + criteriaPercentage > 100 &&
        <Heading level="4" fill> 
        Ce nouveau critère dépassera un poids de 100%.
        </Heading>
      }

      <Box fill pad="medium" direction={ size !== "large" ? "column": "row"} align="center" justify="around">
        <Box width="medium">
          <TextInput 
            style={{margin: "7px"}} 
            type="text"
            name="criteriaFr" ref={register({required: true})} 
            placeholder="[FRANCAIS] Nom du critère" />
          {
            englishAvailable && <TextInput 
              style={{margin: "7px"}} 
              name="criteriaEn" 
              type="text"
              ref={register({required: true})} 
              placeholder="[ANGLAIS] Nom du critère" />
          }
          <TextInput 
            style={{margin: "7px"}} 
            type="number"
            min="0"
            reverse
            onChange={(e) => setFormCriterionWeight(parseInt(e.target.value, 10))}
            icon={<Heading level="3">%</Heading>}
            name="weight" 
            ref={
              register(
                {
                  required: true,
                  min: 0,
                  pattern: {
                    value: /^[1-9][0-9]?$|^100$/,
                    message: ""
                  }
                }
              )
            } 
            placeholder="Poids du critère (%)" />
        </Box>

        <Button
          icon={<Save />}
          type="button"
          margin="medium" 
          disabled={!formState.isValid || actionStarted} 
          onClick={() => addCriterion()} 
          label="Ajouter" 
          secondary color="pexeGreen" />
      </Box>
    </Form>

  }

  return null;
   
}

export default CriterionForm;