import { Anchor, Box, Nav, ResponsiveContext } from 'grommet';
import { AddCircle, Calendar, Home, Logout } from 'grommet-icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from '../../services/authService';

const CommonHeader = (): JSX.Element => {

  const history = useHistory();

  const triggerLogout = () => {
    logout();
    history.push("/");
  }

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          as="header"
          direction="row"
          elevation="small"
          align="center"
          justify="between"
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        >
          <Nav direction="row" animation="fadeIn" flex justify="between">
            <img
              src={`${process.env.PUBLIC_URL}/pexe-logo.jpg`}
              alt="Pexe"
              style={{ width: '85px', marginLeft: '15px', maxHeight: '100px' }}
            />
            <Box flex direction="row" alignContent="center" justify="center">
              <Anchor
                color="lightDark"
                weight="normal"
                icon={<Home color="pexeGreen" />}
                alignSelf="center"
                onClick={() => history.push('/backoffice')}
                label={size !== 'small' ? 'Accueil' : ''}
              />
              <Anchor
                color="lightDark"
                weight="normal"
                icon={<AddCircle color="pexeGreen" />}
                alignSelf="center"
                margin={{ left: size !== 'small' ? 'large' : 'small' }}
                onClick={() => history.push('/backoffice/createEvent')}
                label={size !== 'small' ? 'Créer un événement' : ''}
              />
              <Anchor
                color="lightDark"
                weight="normal"
                icon={<Calendar color="pexeGreen" />}
                margin={{ left: size !== 'small' ? 'large' : 'small' }}
                alignSelf="center"
                onClick={() => history.push('/backoffice/pastEvents')}
                label={size !== 'small' ? 'Événements passés' : ''}
              />
            </Box>
            <Anchor
              color="lightDark"
              weight="normal"
              icon={<Logout color="status-error" />}
              margin={{ right: 'small' }}
              alignSelf="center"
              onClick={() => triggerLogout()}
              label={size !== 'small' ? 'Déconnexion' : ''}
            />
          </Nav>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default CommonHeader;
