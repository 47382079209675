/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

import React, { FunctionComponent, useContext } from 'react';
import { Box, Button, Form, Heading, ResponsiveContext, TextInput } from "grommet"
import { useForm } from 'react-hook-form';
import { Checkmark, Trash } from 'grommet-icons';
import { Company } from '../../../entities/company.interface';
import BackofficeService from '../../../services/backofficeService';


type CompanyComponentTypes = {
  company: Company,
  englishAvailable: boolean,
  deleteCompany: any,
  actionStarted: boolean,
  updateCompany: any
}
const CompanyComponent: FunctionComponent<CompanyComponentTypes>
  = ({ company, deleteCompany, actionStarted, updateCompany, englishAvailable }) => {

    const size = useContext(ResponsiveContext);

    const { register, formState, getValues } = useForm({ mode: 'all' });

    return <Box fill flex pad="medium" direction="column" align="center" justify="between">
      <Form >
        <Box width={size === "large" ? "large" : "medium"} pad="medium">
          <TextInput
            ref={register({ required: true })}
            defaultValue={company.companyName}
            style={{ margin: "7px" }}
            name="companyName"
            placeholder="Nom de l'entité" />
          <TextInput
            ref={register()}
            defaultValue={company.textFr}
            style={{ margin: "7px" }}
            name="textFr"
            placeholder="[FR] Sujet de présentation" />
          {
            englishAvailable &&
            <TextInput
              ref={register()}
              defaultValue={company.textEn && company.textEn !== " " ? company.textEn : ""}
              style={{ margin: "7px" }}
              name="textEn"
              placeholder="[ANGLAIS] Sujet de présentation" />
          }

          <Heading level="4">
            Fiche de contact
          </Heading>

          <TextInput
            style={{ margin: "7px" }}
            name="speakerFirstName"
            defaultValue={company.speakerFirstName}
            ref={register()}
            placeholder="Prénom de l'intervenant" />
          <TextInput
            style={{ margin: "7px" }}
            name="speakerLastName"
            defaultValue={company.speakerLastName}
            ref={register()}
            placeholder="Nom de l'intervenant" />
          <TextInput
            style={{ margin: "7px" }}
            name="speakerEmail"
            defaultValue={company.speakerEmail}
            ref={register(
              {
                pattern: {
                  message: "",
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                }
              }
            )}
            placeholder="Adresse mail" />
          <TextInput
            style={{ margin: "7px" }}
            name="speakerPhone"
            defaultValue={company.speakerPhone}
            ref={register(
              {
                pattern: {
                  message: "",
                  value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
                }
              }
            )
            }
            placeholder="Numéro de téléphone" />

        </Box>
      </Form>

      <Box pad="small" flex direction="row" justify="around" align="center">
        <Button
          type="button"
          margin={{ horizontal: "small" }}
          disabled={!formState.isValid || actionStarted}
          icon={<Checkmark />}
          label="Enregistrer"
          color="pexeGreen"
          onClick={() => updateCompany({ ...company, ...getValues() })}
        />
        <Button
          type="button"
          label="Supprimer"
          style={{ opacity: !formState.isValid || actionStarted ? "0.3" : "1" }}
          color="status-critical"
          icon={<Trash />}
          onClick={() => deleteCompany(company.id)}
        />
      </Box>
    </Box>
  }
export default CompanyComponent;
