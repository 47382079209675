/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { FunctionComponent, useContext, useState } from 'react';
import { Box, DateInput, Form, Heading, ResponsiveContext, TextArea, TextInput } from "grommet";
import { Controller, useForm } from 'react-hook-form';
import TextareaAutosize from 'react-autosize-textarea';
import PexeEvent from '../../../entities/event.interface';

const EventInformation: FunctionComponent<{event: PexeEvent, register: any, control: any}> = ({event, register, control}) => {


  const size = useContext(ResponsiveContext);

  return <Form style={{display: 'flex', justifyContent: 'center'}}>
    <Box flex direction={size === "large" ? "row" : "column"} justify="center" alignSelf="center" width="xlarge">

      <Box direction="column" fill alignSelf="start" height="fill">
        <Box pad="small">
          <TextInput 
            name="eventName"
            defaultValue={event.eventName} placeholder="Nom de l'événement" height="fill" 
            ref={register({required: true})}
          />
        </Box>

        <Box flex direction="row" justify="between" pad="small">
          <Heading level="3" responsive > Date événement </Heading>
          <Box width="small" alignSelf="center">
            <Controller
              name="date"
              defaultValue={event.date}
              control={control}
              render={(
                { onChange }
              ) => (
                <DateInput
                  name="date"
                  format="dd/mm/yyyy"
                  defaultValue={event.date}
                  onChange={(e) => onChange(e.value)}
                />
              )}
            />
          </Box>
        </Box>

      </Box>     

      <Box direction="column" fill alignSelf="start">
        <Box pad="small">
          <TextareaAutosize 
            name="presentationTextFr"
            style={{fontFamily: 'inherit', fontSize: 'inherit', resize: 'vertical', padding: '11px'}}
            placeholder="Description française" 
            defaultValue={event.presentationTextFr}
            ref={register()}
          />
        </Box>

        { event.en_available && 
        <Box pad="small">
          <TextareaAutosize 
            name="presentationTextEn"
            style={{fontFamily: 'inherit', fontSize: 'inherit', resize: 'vertical', padding: '11px'}}
            placeholder="Description anglaise" 
            defaultValue={event.presentationTextEn} 
            ref={register()}
          />
        </Box>
        }

        <Box pad="small">
          <TextareaAutosize 
            name="subPresentationTextFr"
            style={{fontFamily: 'inherit', fontSize: 'inherit', resize: 'vertical', padding: '11px'}}
            placeholder="Sous-description française" 
            defaultValue={event.subPresentationTextFr}
            ref={register()}
          />
        </Box>

        { event.en_available && 
        <Box pad="small">
          <TextareaAutosize 
            name="subPresentationTextEn"
            style={{fontFamily: 'inherit', fontSize: 'inherit', resize: 'vertical', padding: '11px'}}
            placeholder="Sous-description anglaise" 
            defaultValue={event.subPresentationTextEn} 
            ref={register()}
          />
        </Box>
        }
      </Box>   

    </Box>
  </Form>
};

export default EventInformation;